/*
 * Core
 * */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { GoogleSignIn } from "shared/components/Auth/GoogleSignIn";
import InputGlobal from "shared/components/Form/InputGlobal";
import SpinLoader, {
  LOADER_COLOR_DARK,
  LOADER_SIZE_LARGE,
  POSITION_ABSOLUTE,
} from "shared/components/SpinLoader";

import { ERROR_STATUS_UNPROCESSABLE } from "shared/constants";
import {
  getErrorEventEmail,
  getErrorEventPassword,
} from "shared/constants/auth.js";

import { simplyClick } from "shared/helpers/analytics.js";
import {
  afterLoginSuccess,
  clearForm,
  prepareLoginData,
} from "shared/helpers/auth";
import { validateEmail, validatePassword } from "shared/helpers/validation";

import { loginUser } from "shared/services/auth";

import { ReactComponent as EyeIcon } from "assets/shared/img/eye.svg";
/*
 * Assets
 * */
import { ReactComponent as EyeIconHide } from "assets/shared/img/eye-hide.svg";

const LoginForm = () => {
  const [errorEmail, setErrorEmail] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [loginData, setLoginData] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const [responseError, setResponseError] = useState(false);

  const { t } = useTranslation("auth");

  /*
   * Effect for clear Form
   * */
  useEffect(() => {
    clearForm(".close__icon", ".background__modal", () => clearLoginForm());
  }, []);

  /*
   * Open Restore Modal and clean login user data
   * */
  const openRestoreModal = () => {
    const loginModal = document.querySelector(".login__modal");
    const restorePassModal = document.querySelector(".restore__modal");
    loginModal.classList.remove("show");
    restorePassModal.classList.add("show");
    document.body.style.overflow = "hidden";
    clearLoginForm();
  };

  /*
   * Clean Form
   * */
  const clearLoginForm = () => {
    setErrorEmail(null);
    setPasswordError(null);
    setResponseError(null);
    setLoginData({});
  };

  /**
   * Handler for change Email
   * @param {object} event
   */
  const handleChangeEmail = (event) => {
    setErrorEmail(null);
    setLoginData({ ...loginData, email: event?.target?.value });
  };

  /**
   * Handler for change Password
   * @param {object} event
   */
  const handleSetPassword = (event) => {
    setPasswordError(null);
    setLoginData({ ...loginData, password: event?.target?.value });
  };

  /*
   * Toggle show Password on click icon eye
   * */
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  /**
   * Handler for submit login form and send request on api
   */
  const handleSubmitLogin = () => {
    const resultEmail = validateEmail(loginData.email);

    if (!resultEmail.status && resultEmail.error) {
      setErrorEmail(resultEmail.error);
      simplyClick(
        getErrorEventEmail(resultEmail.error, "sign_in"),
        null,
        false
      );
    }

    const resultPassword = validatePassword(loginData.password, null, true);

    if (!resultPassword.status) {
      setPasswordError(resultPassword.errorPassword.text);
      simplyClick(
        getErrorEventPassword(resultPassword.errorPassword.type, "sign_in"),
        null
      );
      return;
    }

    if (resultEmail.status && resultPassword.status) {
      setLoader(true);
      loginUser(prepareLoginData(loginData))
        .then((resp) => cbLoginSuccess(resp))
        .catch((err) => responseErrorCb(err));
    }
  };

  /**
   * Success api response
   * @param {object} response APi response object
   */
  const cbLoginSuccess = (response) => {
    afterLoginSuccess(
      response,
      () => setLoginData({}),
      () => setLoader(false)
    );
  };

  /**
   * Failed request on api
   * @param {object} responseErr failed request on api
   */
  const responseErrorCb = (responseErr) => {
    setLoader(false);
    if (responseErr.response.status === ERROR_STATUS_UNPROCESSABLE) {
      const { data } = responseErr.response;
      let errorText = "";
      data.errors.forEach((error) => {
        if (error.name === "password") {
          setPasswordError(error.message);
          simplyClick("err_lnd_sign_in_validation_notvalid", null);
        } else if (error.name === "email") {
          setErrorEmail(error.message);
          simplyClick("err_lnd_sign_in_validation_notvalid", null);
        } else {
          errorText +=
            errorText.length > 0 ? " " + error.message : error.message;
        }
      });
    } else {
      setResponseError(t("loginRespError"));
    }
  };

  return (
    <>
      {loader && (
        <SpinLoader
          color={LOADER_COLOR_DARK}
          size={LOADER_SIZE_LARGE}
          position={POSITION_ABSOLUTE}
          withBlur={true}
          isFormBlur={true}
        />
      )}

      <div className="login__body">
        <span className="label_global">{t("labelEmail")}</span>
        <InputGlobal
          className={"register__email"}
          handleChange={(e) => handleChangeEmail(e)}
          name="email"
          value={loginData.email}
          error={!!errorEmail}
          errorText={errorEmail}
          placeholder={t("placeholderEmail")}
          autoComplete={"on"}
        />
        <span className="label_global">{t("passwordLabel")}</span>
        <InputGlobal
          className={"register__password"}
          handleChange={(val) => handleSetPassword(val)}
          name="change-password"
          type={showPassword ? "text" : "password"}
          value={loginData.password}
          error={!!passwordError}
          errorText={passwordError}
          placeholder={t("passwordPlaceholder")}
          rightIconSrc={showPassword ? <EyeIconHide /> : <EyeIcon />}
          handleClickRightIcon={toggleShowPassword}
          passwordShowIcon={showPassword}
          autoComplete={"on"}
        />
      </div>
      <div className="login__footer">
        <button onClick={handleSubmitLogin} className="form__button">
          {t("signInButton")}
        </button>
        <div onClick={openRestoreModal} className="forgot__pass">
          {t("forgotPassButton")}
        </div>
        {responseError && <p className="error response">{responseError}</p>}
        {import.meta.env.VITE_APP_GOOGLE_AUTH_CLIENT_ID && (
          <>
            <span className="line"></span>
            <GoogleSignIn
              setLoader={setLoader}
              setResponseError={setResponseError}
            />
          </>
        )}
      </div>
    </>
  );
};

export default LoginForm;
